import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
// import { FaGithub } from "react-icons/fa"
import FLCIcon1 from "../../images/project_card_icons/fun_learning_company_website/fun_learning_company_website.jpg"
import FLCIcon2 from "../../images/project_card_icons/fun_learning_company_website/fun_learning_company_website_2.jpg"
import FLCIcon3 from "../../images/project_card_icons/fun_learning_company_website/fun_learning_company_website_3.jpg"

const ProjectFLCWebsite = () => (
  <Layout>
    <SEO title="FLC Website" />
    <section className="projects__page">
    <h1 className="page-title dark-theme-text">Project Highlights</h1>
      <div className="projects__card--large">
        <div className="projects__card__title">
          <h3>Fun Learning Company Website</h3>
          <p>Fun Learning Company is an eLearning company, and I designed its website.</p>
        </div>
        <div className="projects__card__description--large">
          <img src={ FLCIcon1 } />
          <p>
            <section className="projects__page__subtitles">Landing site: <a href="https://funlearningcompany.com" target="_blank" rel="noopener noreferrer">funlearningcompany.com</a></section>
            <ul>
              <li>Ground-up design with PHP and deployed utilizing LAMP stack.</li>
              <li>Menu items and card components dynamically render from non-relational database.</li>
              <li>Original vector artwork.</li>
              <li>Animated menu items and buttons</li>
              <li>Custom JavaScript auto/manual carousel banner</li>
            </ul>
          </p>
        </div>
        <div className="projects__card__description--large">
          <img src={ FLCIcon2 } />
          <p>
            <section className="projects__page__subtitles">Summer Camps page: <a href="https://funlearningcompany.com/pages/summer.php" target="_blank" rel="noopener noreferrer">/summer.php</a></section>
            <ul>
              <li>Summer camp data pulled with Jquery and uniquely displayed in accordion dropdown menus.</li>
              <li>Smooth CSS animations and clean, responsive UI.</li>
              <li>Algorithmically loaded table data according to age group and other fields specified in the database</li>
            </ul>
          </p>
        </div>
        <div className="projects__card__description--large">
          <img src={ FLCIcon3 } />
          <p>
            <section className="projects__page__subtitles">Moodle site: <a href="https://funlearningcompany.com/moodle/" target="_blank" rel="noopener noreferrer">funlearningcompany.com/moodle</a></section>
            <ul>
              <li>Integrated Moodle LMS framework for course management, enrollment, and payments.</li>
              <li>Custom SCSS color theme.</li>
              <li>Modification of relational database structures for configured access across full site.</li>
              <li>Set up PayPal Sandbox API for secure payment testing.</li>
              <li>Ridiculous amount of backend configuration on CentOS VPS to mount domains and build custom Dovecot mail server. I truly have no interest in dealing with mail servers ever again.</li>
            </ul>
          </p>
        </div>
        <div className="projects__card__links">
          <a href="https://funlearningcompany.com/" target="_blank" rel="noopener noreferrer" className="btn--primary">Visit site</a>
          <Link to="../" className="btn--secondary">Go back</Link>
          {/* <a href="https://github.com/junosprite007/funlearningcompany/" className="social-media"><FaGithub /></a> */}
        </div>
      </div>
    </section>
  </Layout>
)

export default ProjectFLCWebsite
